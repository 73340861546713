const awsmobile = {
  "aws_project_region": "us-east-1",
  "aws_cognito_region": "us-east-1",
  "aws_user_pools_id": "us-east-1_lr3vW0uzo",
  "aws_user_pools_web_client_id": "67cbg0cq7k3p6k1ri62oerjric",
  "oauth": {},
  "aws_cognito_username_attributes": [],
  "aws_cognito_social_providers": [],
  "aws_cognito_signup_attributes": [
      "EMAIL"
   ],
  "aws_cognito_mfa_configuration": "OFF",
  "aws_cognito_mfa_types": [
      "SMS"
   ],
  "aws_cognito_password_protection_settings": {
       "passwordPolicyMinLength": 8,
       "passwordPolicyCharacters": []
   },
  "aws_cognito_verification_mechanisms": [
      "EMAIL"
   ],
  "aws_cloud_logic_custom": [
      {
          "name": "secure-api",
          "endpoint": "https://8j1oceewol.execute-api.us-east-1.amazonaws.com/main/",
          "region": "us-east-1"
      }
  ]
};
export default awsmobile;
