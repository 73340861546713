import React, { useEffect, useState } from "react";
import {
  List,
  Datagrid,
  TextField,
  DateField,
  EditButton,
  ShowButton,
  SimpleShowLayout,
  ReferenceManyField,
  Show,
  Create,
  Edit,
  SimpleForm,
  TextInput,
  DateInput,
  SelectInput,
  useShowController,
} from "react-admin";
import FileUpload from "../utils/file/FileUpload";

export const ActivityUserList = (props) => {
  return (
    <List {...props}>
      <Datagrid>
        <TextField source="resource_name" label="Company" />
        <DateField source="date_created" label="Date Created" />
        <EditButton basePath="/client" />
        <ShowButton basePath="/client" />
      </Datagrid>
    </List>
  );
};

export const ActivityUserEdit = (props) => {
  const controllerProps = useShowController(props);
  const { record } = controllerProps;
  const [uid, setUid] = useState("");
  const [type, setType] = useState("");
  const [activityComment, setActivityComment] = useState(""); // Add state for activity_comment



  useEffect(() => {
    if (record) {
      setUid(record.id || "");
      setType(record.type || "");
      setActivityComment(record.activity_comment || ""); // Initialize activity_comment
  
    }
  }, [record]);

  return (
    <Edit title="Edit Client" {...props}>
      <>
        <SimpleForm>
          <TextInput disabled source="id" />
          <DateInput
            disabled
            source="date_updated"
            label="Date Updated"
          ></DateInput>
          <DateInput
            disabled
            source="date_created"
            label="Date Created"
          ></DateInput>
          <TextInput  
            source="activity_comment" 
            label="Activity Comment"
            onChange={(e) => setActivityComment(e.target.value)} // Handle input change
          ></TextInput>
          <SelectInput
            source="user_activity_status"
            label="Activity Status"
            choices={[
              { id: "completed", name: "Completed" },
              { id: "assigned", name: "Assigned" },
              { id: "approved", name: "Approved" },
              { id: "rejected", name: "Rejected" },
              { id: "finalized", name: "Finalized" },
            ]}
          />
          {type === "tiktok_publish" && (
            <TextInput disabled source="review_video_file_path" />
          )}
        </SimpleForm>
        {type === "tiktok_publish" && <FileUpload source={uid} activityComment={activityComment}/>}
      </>
    </Edit>
  );
};

export const ActivityUserCreate = (props) => {
  return (
    <Create title="Create Client" {...props}>
      <SimpleForm>
        <TextInput source="resource_name" label="Company Name" />
      </SimpleForm>
    </Create>
  );
};

export const ActivityUserShow = (props) => {
  return (
    <Show>
      <SimpleShowLayout>
        <TextField source="resource_name" />
        <ReferenceManyField
          label="Campaigns"
          reference="campaign"
          target="parent_id"
        >
          <Datagrid>
            <TextField source="resource_name" />
            <EditButton basePath="/campaign" />
            <ShowButton basePath="/campaign" />
          </Datagrid>
        </ReferenceManyField>
      </SimpleShowLayout>
    </Show>
  );
};
