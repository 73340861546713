import React, { useState, useEffect } from "react";
import { Authenticator} from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import Header from "./utils/auth/getHeader";
import { API } from "aws-amplify";
import AdminWrapper from "./AdminWrapper";

/*
  const signUpFields= [
    {
      label: 'My user name',
      key: 'username',
      required: true,
      displayOrder: 1,
      type: 'string'
    },
    {
      label: 'Password',
      key: 'password',
      required: true,
      displayOrder: 2,
      type: 'password'
    },
    {
      label: 'PhoneNumber',
      key: 'phone_number',
      required: true,
      displayOrder: 3,
      type: 'string'
    },
    {
      label: 'Email',
      key: 'email',
      required: true,
      displayOrder: 4,
      type: 'string'
    }
  ]
*/

function App() {

  const [header, setheader] = useState(null);
  const [apiPath, setApiPath] = useState(null);

  useEffect(() => {
    getRequiredData();
  }, []);

  const getRequiredData = async () => {
    const header = await Header.getHeader();
    const apiPath = await API.endpoint("secure-api");
    setApiPath(apiPath);
    setheader(header);
    console.log(apiPath);
    console.log(header);
  };

  return (
    <div style={{marginTop:'12em'}} >
       <Authenticator loginMechanisms={['email','phone_number',]}  >
      <AdminWrapper header={header} apiPath={apiPath}></AdminWrapper>
      </Authenticator>

    </div>
  );
}


export default App
