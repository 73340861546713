import React, { useState, useEffect} from 'react';
import {
  useNotify,
  useRefresh,
  useDataProvider,
} from "react-admin";
import {
  Card,
  CardContent,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  DialogContent,
  CircularProgress
} from '@mui/material';
import Header from "../utils/auth/getHeader";
import { API } from "aws-amplify";

// A function to validate the email format
const validateEmail = (email) => {
  const re = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
  return re.test(email);
};

const PremiumCampaignScreen = () => {
  const [email, setEmail] = useState('');
  const [campaign, setCampaign] = useState({});
  const [campaigns, setCampaigns] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const refresh = useRefresh();

  useEffect(() => {
    setLoading(true);
    dataProvider
      .getList("campaign", {
        pagination: { page: 1, perPage: 100 },
        sort: { field: "resource_name" },
        filter: {},
      })
      .then(({ data }) => {
        setCampaigns(data);
      })
      .catch((error) => {
        notify("Error loading campaigns: " + error.message, "warning");
      })
      .finally(() => {
        setLoading(false);
      });
  }, [dataProvider, notify]); // Dependencies for useEffect.


  const handleSubmit = async () => {

        // Validate email before submitting
        if (!validateEmail(email)) {
          setError('Invalid email address');
          notify('Please enter a valid email', { type: 'warning' });
          return;
        }
    
        if (!campaign.id) {
          notify('Please select a campaign', { type: 'warning' });
          return;
        }

    try {
      const header = await Header.getHeader();
      await API.post("secure-api", "api/admin/premiumcampaign", {
        headers: {
          ...header,
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Origin": "*",
        },
        body: {
          premium_email: email,
          campaign_id: campaign.id, // Adding the campaign_id to the payload
        },
      });
      notify("Users updated successfully", "info");
      refresh();
    } catch (error) {
      // Handle error case
      notify("Error: Not Updated", "warning");
    }
  };

  return (
    <Card>
      <CardContent>
        <h2>Assign a Premium Campaign</h2>
        <FormControl fullWidth style={{ marginBottom: '20px' }}>
          <TextField
            label="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            error={!!error}
            helperText={error}
            fullWidth
            required
          />
        </FormControl>

        <DialogContent>
          {loading ? (
            <CircularProgress />
          ) : (
            <FormControl fullWidth style={{ marginBottom: '20px' }}>
              <InputLabel id="campaign-select-label">Campaign</InputLabel>
              <Select
                labelId="campaign-select-label"
                id="campaign-select"
                value={campaign.id || ''} // Handle empty state with ''
                onChange={(e) => {
                  const selectedCampaign = campaigns.find(
                    (c) => c.id === e.target.value
                  );
                  setCampaign(selectedCampaign);
                }}
                required
              >
                {campaigns.map((campaignItem) => (
                  <MenuItem key={campaignItem.id} value={campaignItem.id}>
                    {campaignItem.resource_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </DialogContent>

        <Button
          onClick={handleSubmit}
          color="primary"
          variant="contained"
          fullWidth
        >
          Submit
        </Button>
      </CardContent>
    </Card>
  );
};

export default PremiumCampaignScreen;
