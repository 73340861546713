import React from "react";


const FileUploadButton = ({ onFileSelect }) => {
  return (
    <div>
      <input type="file" onChange={(e) => onFileSelect(e)} accept="video/*" />
    </div>
  );
};

export default FileUploadButton;
