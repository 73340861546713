import React from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  ShowButton,
  SimpleShowLayout,
  Show
} from "react-admin";
import { Create, Edit, SimpleForm, TextInput } from "react-admin";





export const FlagList = (props) => {
  return (
    <List {...props}>
      <Datagrid>
        <TextField source="resource_name" label="Flag Name" />
        <EditButton/>
        <ShowButton/>
      </Datagrid>
    </List>
  );
};

export const FlagEdit = (props) => {
  return (
    <Edit title="Edit Flag" {...props}>
      <SimpleForm>
        <TextInput source="resource_name" label="Flag Name" />
      </SimpleForm>
    </Edit>
  );
};


export const FlagCreate = (props) => {
  return (
    <Create title="Create Flag" {...props}>
      <SimpleForm>
        <TextInput source="resource_name" label="Flag Name" />
      </SimpleForm>
    </Create>
  );
};



export const FlagShow = (props) => {

  return (
    <Show {...props}>
     <SimpleShowLayout>
        <TextField source="resource_name" label="Flag Name" />
      </SimpleShowLayout>
    </Show>
  );
};

