import React, { useState, useEffect } from "react";
import {
  List,
  Datagrid,
  TextField,
  DateField,
  EditButton,
  ShowButton,
  SimpleShowLayout,
  ReferenceManyField,
  Show,
  Create,
  Edit,
  SimpleForm,
  TextInput,
  DateInput,
  SelectInput,
  UrlField,
  FilterListItem,
  FilterList,
  Pagination,
  Button,
  useNotify,
  useListContext,
  useDataProvider,
  useShowController,
  useRefresh,
  SimpleFormIterator,
  ArrayInput,
  useGetList
} from "react-admin";
import { RichTextInput } from "ra-input-rich-text";
import { Card, CardContent } from "@material-ui/core";
import Header from "../utils/auth/getHeader";
import { API } from "aws-amplify";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField as MuiTextField,
  Button as MuiButton,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@material-ui/core";


const BulkActionSendSms = ({ campaign_id, initial_smsText }) => {
  const [open, setOpen] = useState(false);
  const [smsText, setSmsText] = useState(initial_smsText); // This should be set to your SMS.invitation field value
  const { selectedIds, onUnselectItems } = useListContext();
  const notify = useNotify();
  const refresh = useRefresh();

  // Function to open the modal dialog
  const handleOpen = () => {
    // Here you would fetch and set the default message from your `SMS.invitation` field
    // setSmsText('Your default SMS invitation text');
    setOpen(true);
  };

  // Function to close the modal dialog
  const handleClose = () => {
    setOpen(false);
  };

  // Function to send SMS
  const handleSend = async () => {
    try {
      const header = await Header.getHeader();
      await API.post("secure-api", "api/admin/sendsms", {
        headers: {
          ...header,
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Origin": "*",
        },
        body: {
          sms_text: smsText,
          user_ids: selectedIds,
          resource_id: campaign_id,
        },
      });
      notify("SMS sent successfully", "info");
      handleClose();
      onUnselectItems();
      refresh();
    } catch (error) {
      notify("Error: SMS not sent", "warning");
    }
  };

  return (
    <>
      <Button label="Send SMS" onClick={handleOpen} />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Send SMS</DialogTitle>
        <DialogContent>
          <MuiTextField
            autoFocus
            margin="dense"
            id="smsText"
            label="SMS Message"
            type="text"
            fullWidth
            multiline
            rows={4}
            value={smsText}
            onChange={(event) => setSmsText(event.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <MuiButton onClick={handleClose} color="primary">
            Cancel
          </MuiButton>
          <MuiButton onClick={handleSend} color="primary">
            Send
          </MuiButton>
        </DialogActions>
      </Dialog>
    </>
  );
};


const BulkActionButtonAccept = ({ campaign_id }) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const { selectedIds, onUnselectItems } = useListContext();
  // Action for button one
  const handleClick = async () => {
    try {
      const header = await Header.getHeader();
      await API.post("secure-api", "api/admin/campaignstatus", {
        headers: {
          ...header,
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Origin": "*",
        },
        body: {
          campaign_status: "accepted",
          user_ids: selectedIds,
          campaign_id: campaign_id, // Adding the campaign_id to the payload
        },
      });
      notify("Users updated successfully", "info");
      onUnselectItems();
      refresh();
    } catch (error) {
      // Handle error case
      notify("Error: Users not updated", "warning");
    }
  };
  return <Button label="Accept" onClick={handleClick} />;
};

const BulkActionButtonNoShipment = ({ campaign_id }) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const { selectedIds, onUnselectItems } = useListContext();
  // Action for button one
  const handleClick = async () => {
    try {
      const header = await Header.getHeader();
      await API.post("secure-api", "api/admin/campaignstatus", {
        headers: {
          ...header,
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Origin": "*",
        },
        body: {
          campaign_status: "no_shipment",
          user_ids: selectedIds,
          campaign_id: campaign_id, // Adding the campaign_id to the payload
        },
      });
      notify("Users updated successfully", "info");
      onUnselectItems();
      refresh();
    } catch (error) {
      // Handle error case
      notify("Error: Users not updated", "warning");
    }
  };
  return <Button label="No Shipment" onClick={handleClick} />;
};

const BulkActionButtonReject = ({ campaign_id }) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const { selectedIds, onUnselectItems } = useListContext();

  // Action for button one
  const handleClick = async () => {
    try {
      const header = await Header.getHeader();
      await API.post("secure-api", "api/admin/campaignstatus", {
        headers: {
          ...header,
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Origin": "*",
        },
        body: {
          campaign_status: 'rejected',
          user_ids: selectedIds,
          campaign_id: campaign_id, // Adding the campaign_id to the payload
        },
      });
      notify("Users updated successfully", "info");
      onUnselectItems();
      refresh();
    } catch (error) {
      // Handle error case
      notify("Error: Users not updated", "warning");
    }
  };
  return <Button label="Reject" onClick={handleClick} />;
};



const BulkActionButtonInvite = ({ campaign_id }) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const { selectedIds, onUnselectItems } = useListContext();

  // Action for button one
  const handleClick = async () => {
    try {
      const header = await Header.getHeader();
      await API.post("secure-api", "api/admin/campaignstatus", {
        headers: {
          ...header,
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Origin": "*",
        },
        body: {
          campaign_status: 'invited',
          user_ids: selectedIds,
          campaign_id: campaign_id, // Adding the campaign_id to the payload
        },
      });
      notify("Users updated successfully", "info");
      onUnselectItems();
      refresh();
    } catch (error) {
      // Handle error case
      notify("Error: Users not updated", "warning");
    }
  };
  return <Button label="Invite" onClick={handleClick} />;
};

const BulkActionButtonMonetize = ({ campaign_id }) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const { selectedIds, onUnselectItems } = useListContext();

  // Action for button one
  const handleClick = async () => {
    try {
      const header = await Header.getHeader();
      await API.post("secure-api", "api/admin/monetizeusers", {
        headers: {
          ...header,
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Origin": "*",
        },
        body: {
          user_ids: selectedIds,
        },
      });
      notify("Users updated successfully", "info");
      onUnselectItems();
      refresh();
    } catch (error) {
      // Handle error case
      notify("Error: Users not updated", "warning");
    }
  };
  return <Button label="Monetize" onClick={handleClick} />;
};


export const CampaignList = (props) => {
  return (
    <List {...props}>
      <Datagrid>
        <TextField source="resource_name" label="Campaign" />
        <DateField source="date_created" label="Date Created" />
        <EditButton basePath="/campaign" />
        <ShowButton basePath="/campaign" />
      </Datagrid>
    </List>
  );
};

export const CampaignEdit = (props) => {
  return (
    <Edit title="Edit Campaign" {...props}>
      <SimpleForm>
        <TextInput source="resource_name" label="Campaign Name (internal)" />
        <TextInput source="title" label="Campaign Title" />
        <SelectInput
          source="type"
          label="Campaign Type"
          choices={[
            { id: "digital", name: "Digital" },
            { id: "regular", name: "Regular" },
            { id: "premium_digital", name: "Premium Digital" },
            { id: "premium_regular", name: "Premium Regular" },
          ]}
        />
        <SelectInput
          source="status"
          label="Campaign Status"
          choices={[
            { id: "draft", name: "Draft" },
            { id: "active", name: "Active" },
            { id: "past", name: "Past" },
            { id: "invite", name: "Invite" },
            { id: "invite_open", name: "Open Invite" },
          ]}
        />
        <DateInput
          disabled
          source="date_created"
          label="Date Created"
        ></DateInput>
        <TextInput source="brand_logo" label="Brand Logo" />
        <TextInput source="brand_name" label="Brand Name" />
        <TextInput source="product_image" label="Product Image" />
        <TextInput source="product_name" label="Product Name" />
        <TextInput source="SMS.invitation" label="SMS Text" />
        <TextInput source="review_site" label="Review Site" />
        <RichTextInput
          source="terms"
          label="Terms"
        />
        <RichTextInput
          source="description"
          label="Description: Shown in invite"
        />
        <RichTextInput
          source="instructions"
          label="Instructions: Shown in campaign list"
        />
        <ArrayInput source="varieties">
          <SimpleFormIterator>
            <TextInput source="product_name" label="Product Name" />
            <TextInput source="review_link" label="Review Link" />
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Edit>
  );
};

export const CampaignCreate = (props) => {
  return (
    <Create title="Create Campaign" {...props}>
      <SimpleForm>
        <TextInput source="resource_name" label="Campaign" />
        <SelectInput
          source="status"
          label="Campaign Status"
          choices={[
            { id: "draft", name: "Draft" },
            { id: "active", name: "Active" },
            { id: "past", name: "Past" },
            { id: "invite", name: "Invite" },
            { id: "invite_open", name: "Open Invite" },
            // ... add more types as needed
          ]}
        />
      </SimpleForm>
    </Create>
  );
};


const Aside = () => {

  const { data: tags, isLoading } = useGetList('tag', {
    pagination: { page: 1, perPage: 100 },
    sort: { field: 'resource_name', order: 'ASC' },
    filter: {}
  });

  return (
    <div style={{ width: 200, margin: "1em" }}>
      <FilterList label="Campaign Status">
        <FilterListItem label="Invited" value={{ campaign_status: "invited" }} />
        <FilterListItem label="Applied" value={{ campaign_status: "applied" }} />
        <FilterListItem label="Accepted" value={{ campaign_status: "accepted" }} />
        <FilterListItem label="Rejected" value={{ campaign_status: "rejected" }} />
        <FilterListItem label="Initiated" value={{ campaign_status: "initiated" }} />
        <FilterListItem label="No Shipment" value={{ campaign_status: "no_shipment" }} />
      </FilterList>

      <FilterList label="Gender">
        <FilterListItem label="Female" value={{ gender: "Female" }} />
        <FilterListItem label="Male" value={{ gender: "Male" }} />
        <FilterListItem label="Other" value={{ gender: "Other" }} />
        <FilterListItem label="Decline" value={{ gender: "Decline" }} />
      </FilterList>

      <FilterList label="Min Age">
        <FilterListItem label="25" value={{ min_age: (new Date().getFullYear() - 25).toString() + "-00-00" }} />
        <FilterListItem label="30" value={{ min_age: (new Date().getFullYear() - 30).toString() + "-00-00" }} />
        <FilterListItem label="35" value={{ min_age: (new Date().getFullYear() - 35).toString() + "-00-00" }} />
        <FilterListItem label="40" value={{ min_age: (new Date().getFullYear() - 40).toString() + "-00-00" }} />
      </FilterList>

      <FilterList label="Max Age">
        <FilterListItem label="30" value={{ max_age: (new Date().getFullYear() - 30).toString() + "-00-00" }} />
        <FilterListItem label="35" value={{ max_age: (new Date().getFullYear() - 35).toString() + "-00-00" }} />
        <FilterListItem label="40" value={{ max_age: (new Date().getFullYear() - 40).toString() + "-00-00" }} />
        <FilterListItem label="45" value={{ max_age: (new Date().getFullYear() - 45).toString() + "-00-00" }} />
      </FilterList>

      <FilterList label="Min TikTok Followers">
        <FilterListItem label="100" value={{ min_follower_count: 100 }} />
        <FilterListItem label="500" value={{ min_follower_count: 500 }} />
        <FilterListItem label="1000" value={{ min_follower_count: 1000 }} />
      </FilterList>
      <FilterList label="Show Flagged">
        <FilterListItem label="Yes" value={{ flagged: true }} />
      </FilterList>

      {/*tag Filter */}
      <FilterList label="Tags">
        {!isLoading && tags
          ? tags.map(tag => (
            <FilterListItem
              key={tag.id}
              label={tag.resource_name || 'Unnamed Flag'} // Fallback to a default string if `flag.name` is not a string
              value={{ tag: tag.id + '+' + tag.resource_name || 'unnamed_flag' }} // Ensure the value is also a valid string
            />
          ))
          : <div>Loading...</div>}
      </FilterList>
    </div>
  );
};

export default Aside;

const UserGrid = (props) => (
  <Datagrid {...props}>
    <TextField source="first_name" label="First Name" />
    <TextField source="last_name" label="Last Name" />
    <TextField source="wallet" label="Wallet" />
    <TextField source="campaign_status" label="Campaign Status" />
    <TextField source="last_sms_time" label="Last SMS Time" />
    <TextField source="credit" label="Points" />
    <TextField source="gender" label="Gender" />
    <TextField source="date_created" label="Date Joined" />
    <TextField
      source="social_tiktok.tiktok_user_info.data.user.follower_count"
      label="Follower#"
    />
    <UrlField
      source="tiktok_url"
      label="TikTok Link"
    />
    <TextField source="cognito_birthdate" label="Birthdate" />
    <TextField label="no_share" source="no_partner_sharing" />
    <TextField label="unsub" source="unsubscribe" />
    <TextField source="address" label="Address" />
    {/*<EditButton basePath="/user" />
    <ShowButton basePath="/user" />*/}
  </Datagrid>
);

const CampaignUserPagination = () => (
  <Pagination rowsPerPageOptions={[50, 100, 200]} />
);


const AssignFlag = ({ open, handleClose }) => {
  const [flag, setFlag] = useState("");
  const [flags, setFlags] = useState([]);
  const [loading, setLoading] = useState(false);
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const refresh = useRefresh();
  const { selectedIds, onUnselectItems } = useListContext();
  useEffect(() => {
    setLoading(true);
    dataProvider
      .getList("flag", {
        pagination: { page: 1, perPage: 100 },
        sort: { field: "resource_name" },
        filter: {},
      })
      .then(({ data }) => {
        setFlags(data);
      })
      .catch((error) => {
        notify("Error loading campaigns: " + error.message, "warning");
      })
      .finally(() => {
        setLoading(false);
      });
  }, [dataProvider, notify]); // Dependencies for useEffect.

  const handleAssign = async () => {
    try {
      const header = await Header.getHeader();
      await API.post("secure-api", "api/admin/flagassign", {
        headers: {
          ...header,
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Origin": "*",
        },
        body: {
          user_ids: selectedIds,
          resource_id: flag.id, // Adding the campaign_id to the payload
        },
      });
      notify("Users updated successfully", "info");
      onUnselectItems();
      refresh();
    } catch (error) {
      // Handle error case
      notify("Error: Users not updated", "warning");
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Assign Flag</DialogTitle>
      <DialogContent>
        {loading ? (
          <p>Loading flags...</p>
        ) : (
          <FormControl fullWidth>
            <InputLabel id="campaign-select-label">Flag</InputLabel>
            <Select
              labelId="campaign-select-label"
              id="campaign-select"
              value={flag.id} // Modified to use campaign.id
              onChange={(e) => {
                // Find the selected campaign object and set it
                const selectedFlag = flags.find(
                  (c) => c.id === e.target.value
                );
                setFlag(selectedFlag);
              }}
            >
              {flags.map((flagItem) => (
                <MenuItem key={flagItem.id} value={flagItem.id}>
                  {flagItem.resource_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleAssign} color="primary" disabled={loading}>
          Assign
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const AssignTag = ({ open, handleClose }) => {
  const [tag, setTag] = useState("");
  const [tags, setTags] = useState([]);
  const [loading, setLoading] = useState(false);
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const refresh = useRefresh();
  const { selectedIds, onUnselectItems } = useListContext();
  useEffect(() => {
    setLoading(true);
    dataProvider
      .getList("tag", {
        pagination: { page: 1, perPage: 100 },
        sort: { field: "resource_name" },
        filter: {},
      })
      .then(({ data }) => {
        setTags(data);
      })
      .catch((error) => {
        notify("Error loading campaigns: " + error.message, "warning");
      })
      .finally(() => {
        setLoading(false);
      });
  }, [dataProvider, notify]); // Dependencies for useEffect.

  const handleAssign = async () => {
    try {
      const header = await Header.getHeader();
      await API.post("secure-api", "api/admin/tagassign", {
        headers: {
          ...header,
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Origin": "*",
        },
        body: {
          user_ids: selectedIds,
          resource_id: tag.id, // Adding the campaign_id to the payload
        },
      });
      notify("Users updated successfully", "info");
      onUnselectItems();
      refresh();
    } catch (error) {
      // Handle error case
      notify("Error: Users not updated", "warning");
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Flag</DialogTitle>
      <DialogContent>
        {loading ? (
          <p>Loading flags...</p>
        ) : (
          <FormControl fullWidth>
            <InputLabel id="campaign-select-label">Tag</InputLabel>
            <Select
              labelId="campaign-select-label"
              id="campaign-select"
              value={tag.id} // Modified to use campaign.id
              onChange={(e) => {
                // Find the selected campaign object and set it
                const selectedTag = tags.find(
                  (c) => c.id === e.target.value
                );
                setTag(selectedTag);
              }}
            >
              {tags.map((tagItem) => (
                <MenuItem key={tagItem.id} value={tagItem.id}>
                  {tagItem.resource_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleAssign} color="primary" disabled={loading}>
          Assign
        </Button>
      </DialogActions>
    </Dialog>
  );
};


const AssignCampaignModal = ({ open, handleClose }) => {
  const [campaign, setCampaign] = useState("");
  const [campaigns, setCampaigns] = useState([]);
  const [loading, setLoading] = useState(false);
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const refresh = useRefresh();
  const { selectedIds, onUnselectItems } = useListContext();
  useEffect(() => {
    setLoading(true);
    dataProvider
      .getList("campaign", {
        pagination: { page: 1, perPage: 100 },
        sort: { field: "resource_name" },
        filter: {},
      })
      .then(({ data }) => {
        setCampaigns(data);
      })
      .catch((error) => {
        notify("Error loading campaigns: " + error.message, "warning");
      })
      .finally(() => {
        setLoading(false);
      });
  }, [dataProvider, notify]); // Dependencies for useEffect.

  const handleAssign = async () => {
    try {
      const header = await Header.getHeader();
      await API.post("secure-api", "api/admin/campaignassign", {
        headers: {
          ...header,
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Origin": "*",
        },
        body: {
          user_ids: selectedIds,
          campaign_id: campaign.id, // Adding the campaign_id to the payload
        },
      });
      notify("Users updated successfully", "info");
      onUnselectItems();
      refresh();
    } catch (error) {
      // Handle error case
      notify("Error: Users not updated", "warning");
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Invite to Campaign</DialogTitle>
      <DialogContent>
        {loading ? (
          <p>Loading campaigns...</p>
        ) : (
          <FormControl fullWidth>
            <InputLabel id="campaign-select-label">Campaign</InputLabel>
            <Select
              labelId="campaign-select-label"
              id="campaign-select"
              value={campaign.id} // Modified to use campaign.id
              onChange={(e) => {
                // Find the selected campaign object and set it
                const selectedCampaign = campaigns.find(
                  (c) => c.id === e.target.value
                );
                setCampaign(selectedCampaign);
              }}
            >
              {campaigns.map((campaignItem) => (
                <MenuItem key={campaignItem.id} value={campaignItem.id}>
                  {campaignItem.resource_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleAssign} color="primary" disabled={loading}>
          Assign
        </Button>
      </DialogActions>
    </Dialog>
  );
};

// Step 3: Custom BulkActionButtons component
const CustomBulkActions = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [flagModalOpen, setFlagModalOpen] = useState(false);
  const [tagModalOpen, setTagModalOpen] = useState(false);

  return (
    <>
      <Button onClick={() => setModalOpen(true)}>Add To Campaign</Button>
      <AssignCampaignModal
        open={modalOpen}
        handleClose={() => setModalOpen(false)}
      />
      <Button onClick={() => setFlagModalOpen(true)}>Flag User</Button>
      <AssignFlag
        open={flagModalOpen}
        handleClose={() => setFlagModalOpen(false)}
      />
      <Button onClick={() => setTagModalOpen(true)}>Tag User</Button>
      <AssignTag
        open={tagModalOpen}
        handleClose={() => setTagModalOpen(false)}
      />
    </>
  );
};


export const CampaignShow = (props) => {
  const controllerProps = useShowController(props);
  const { record } = controllerProps;
  const campaign_id = record ? record.id : null;
  const [smsText, setSmsText] = useState("");

  useEffect(() => {
    if (record) {
      setSmsText(record.SMS?.invitation || ""); // Using optional chaining in case SMS or invitation is not present
    }
  }, [record]);

  return (
    <Show {...props}>
      <SimpleShowLayout>
        <TextField source="resource_name" label="Campaign Name" />
        <TextField source="SMS.invitation" label="SMS Text" />
        <ReferenceManyField
          label="Users in this Campaign"
          reference="campaignuser"
          target="campaign_id"
          pagination={null}
        >
          <Card>
            <CardContent style={{ display: "flex", paddingTop: "50px" }}>
              <Aside />
              <UserGrid
                {...props}
                bulkActionButtons={
                  <>
                    <BulkActionButtonAccept campaign_id={campaign_id} />
                    <BulkActionButtonNoShipment campaign_id={campaign_id} />
                    <BulkActionButtonReject campaign_id={campaign_id} />
                    <BulkActionButtonInvite campaign_id={campaign_id} />
                    <BulkActionButtonMonetize campaign_id={campaign_id} />
                    <CustomBulkActions />
                    <BulkActionSendSms
                      campaign_id={campaign_id}
                      initial_smsText={smsText}
                    />{" "}
                  </>
                }
              />
            </CardContent>
          </Card>
          <CampaignUserPagination />
        </ReferenceManyField>
      </SimpleShowLayout>
    </Show>
  );
};