import { Auth } from "aws-amplify";
const getHeader = async () => {
  const user = await Auth.currentAuthenticatedUser();
  const token = user.signInUserSession.idToken.jwtToken;

  return {
    Authorization: token,
  };
};
const getUser = async () => {
  const user = await Auth.currentAuthenticatedUser();


  return user ;
};


const Header = { getHeader,getUser };
export default Header;
