import React from "react";
import { Admin, Resource } from "react-admin";
import simpleRestProvider from "ra-data-simple-rest";
import { fetchUtils } from "react-admin";
//import {
//  ClientCreate,
//  ClientEdit,
//  ClientList,
//  ClientShow
//} from "./components/Client";

import {
  UserEdit,
  UserList,
  UserShow
} from "./components/User";

import {
  CampaignCreate,
  CampaignEdit,
  CampaignList,
  CampaignShow
} from "./components/Campaign";

import {
  TagCreate,
  TagEdit,
  TagList,
  TagShow
} from "./components/Tag";

import {
  FlagCreate,
  FlagEdit,
  FlagList,
  FlagShow
} from "./components/Flag";

import {
  ActivityCreate,
  ActivityEdit,
  ActivityList,
  ActivityShow,
} from "./components/Activity";
import {
  ActivityUserCreate,
  ActivityUserEdit,
  ActivityUserShow,
} from "./components/ActivityUser";
import PremiumCampaignScreen from "./components/PremiumCampaignScreen";
//import { UserEdit, UserList, UserShow } from "./components/User";
//import {
//  CampaignUserList,
//  CampaignUserShow,
//  CampaignUserCreate
//} from "./components/CampaignUser";
//import {
//  ClientCampaignList,
//  ClientCampaignCreate,
//} from "./components/ClientCampaign";

function AdminWrapper({ header, apiPath }) {

  console.log(header)

  const httpClient = async (url, options = {}) => {
    options.headers = new Headers({ ...header, ...options?.headers });
    return fetchUtils.fetchJson(url, options);
  };

  const dataProvider = simpleRestProvider(apiPath + "api/admin", httpClient);

  return (
    <>
      <Admin dataProvider={dataProvider}>
        <Resource
          name="campaign"
          options={{ label: "Campaigns" }}
          list={CampaignList}
          edit={CampaignEdit}
          create={CampaignCreate}
          show={CampaignShow}
        />
        <Resource
          name="activityuser"
          options={{ label: "Activity User" }}
          edit={ActivityUserEdit}
          create={ActivityUserCreate}
          show={ActivityUserShow}
        />
        <Resource
          name="activity"
          options={{ label: "Activites" }}
          list={ActivityList}
          edit={ActivityEdit}
          create={ActivityCreate}
          show={ActivityShow}
        />

        <Resource
          name="user"
          options={{ label: "Users" }}
          list={UserList}
          edit={UserEdit}
          show={UserShow}
        />

        <Resource
          name="tag"
          options={{ label: "Tags" }}
          list={TagList}
          create={TagCreate}
          edit={TagEdit}
          show={TagShow}
        />

        <Resource
          name="flag"
          options={{ label: "Flags" }}
          list={FlagList}
          create={FlagCreate}
          edit={FlagEdit}
          show={FlagShow}
        />

        <Resource
          name="premiumcampaign"
          options={{ label: 'Premium Campaign' }}
          list={PremiumCampaignScreen} // Using list here for simplicity, can also use 'edit' or 'create'
        />

        {/*
             <Resource
          name="client"
          options={{ label: "Clients" }}
          list={ClientList}
          edit={ClientEdit}
          create={ClientCreate}
          show={ClientShow}
        />
        <Resource
          name="usercampaign"
          options={{ label: "User Campaign" }}
          list={UserCampaignList}
          create={UserCampaignCreate}
        />
        <Resource
          name="clientcampaign"
          options={{ label: "Client Campaign" }}
          list={ClientCampaignList}
  create={ClientCampaignCreate} 
  />*/}
      </Admin>
    </>
  );
}

export default AdminWrapper;
