import React, { useState, useEffect } from "react";
import {
  List,
  Datagrid,
  TextField,
  ShowButton,
  SimpleShowLayout,
  Show,
  Edit,
  SimpleForm,
  TextInput,
  DateInput,
  FilterList,
  FilterListItem,
  Pagination,
  UrlField,
  useNotify,
  useUnselectAll,
  useRefresh,
  useDataProvider,
  useListContext,
  FilterLiveSearch,
} from "react-admin";
import {
  Card,
  CardContent,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField as MuiTextField,
  Button as MuiButton,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@material-ui/core";
import Header from "../utils/auth/getHeader";
import { API } from "aws-amplify";


const BulkActionSendSms = () => {
  const [open, setOpen] = useState(false);
  const [smsText, setSmsText] = useState(""); // This should be set to your SMS.invitation field value
  const { selectedIds, onUnselectItems } = useListContext();
  const notify = useNotify();
  const refresh = useRefresh();

  // Function to open the modal dialog
  const handleOpen = () => {
    // Here you would fetch and set the default message from your `SMS.invitation` field
    // setSmsText('Your default SMS invitation text');
    setOpen(true);
  };

  // Function to close the modal dialog
  const handleClose = () => {
    setOpen(false);
  };

  // Function to send SMS
  const handleSend = async () => {
    try {
      const header = await Header.getHeader();
      await API.post("secure-api", "api/admin/sendsms", {
        headers: {
          ...header,
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Origin": "*",
        },
        body: {
          sms_text: smsText,
          user_ids: selectedIds,
          resource_id: 'profile',
        },
      });
      notify("SMS sent successfully", "info");
      handleClose();
      onUnselectItems();
      refresh();
    } catch (error) {
      notify("Error: SMS not sent", "warning");
    }
  };

  return (
    <>
      <Button label="Send SMS" onClick={handleOpen}>Send SMS</Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Send SMS</DialogTitle>
        <DialogContent>
          <MuiTextField
            autoFocus
            margin="dense"
            id="smsText"
            label="SMS Message"
            type="text"
            fullWidth
            multiline
            rows={4}
            value={smsText}
            onChange={(event) => setSmsText(event.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <MuiButton onClick={handleClose} color="primary">
            Cancel
          </MuiButton>
          <MuiButton onClick={handleSend} color="primary">
            Send
          </MuiButton>
        </DialogActions>
      </Dialog>
    </>
  );
};


const AssignCampaignModal = ({ open, handleClose}) => {
  const [campaign, setCampaign] = useState("");
  const [campaigns, setCampaigns] = useState([]);
  const [loading, setLoading] = useState(false);
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const unselectAll = useUnselectAll("users");
  const refresh = useRefresh();
  const { selectedIds } = useListContext();
  console.log(selectedIds);
  useEffect(() => {
    setLoading(true);
    dataProvider
      .getList("campaign", {
        pagination: { page: 1, perPage: 100 },
        sort: { field: "resource_name" },
        filter: {},
      })
      .then(({ data }) => {
        setCampaigns(data);
      })
      .catch((error) => {
        notify("Error loading campaigns: " + error.message, "warning");
      })
      .finally(() => {
        setLoading(false);
      });
  }, [dataProvider, notify]); // Dependencies for useEffect.

  const handleAssign = async () => {
    try {
      const header = await Header.getHeader();
      await API.post("secure-api", "api/admin/campaignassign", {
        headers: {
          ...header,
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Origin": "*",
        },
        body: {
          user_ids: selectedIds,
          campaign_id: campaign.id, // Adding the campaign_id to the payload
        },
      });
      notify("Users updated successfully", "info");
      refresh();
      unselectAll();
    } catch (error) {
      // Handle error case
      notify("Error: Users not updated", "warning");
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Invite to Campaign</DialogTitle>
      <DialogContent>
        {loading ? (
          <p>Loading campaigns...</p>
        ) : (
          <FormControl fullWidth>
            <InputLabel id="campaign-select-label">Campaign</InputLabel>
            <Select
              labelId="campaign-select-label"
              id="campaign-select"
              value={campaign.id} // Modified to use campaign.id
              onChange={(e) => {
                // Find the selected campaign object and set it
                const selectedCampaign = campaigns.find(
                  (c) => c.id === e.target.value
                );
                setCampaign(selectedCampaign);
              }}
            >
              {campaigns.map((campaignItem) => (
                <MenuItem key={campaignItem.id} value={campaignItem.id}>
                  {campaignItem.resource_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleAssign} color="primary" disabled={loading}>
          Assign
        </Button>
      </DialogActions>
    </Dialog>
  );
};

// Step 3: Custom BulkActionButtons component
const CustomBulkActions = () => {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <>
      <Button onClick={() => setModalOpen(true)}>Assign To Campaign</Button>
      <AssignCampaignModal open={modalOpen} handleClose={() => setModalOpen(false)}/>
    </>
  );
};


const Aside = () => (
  <div style={{ width: 200, margin: "1em" }}>
    <FilterLiveSearch source="q" label="Search" />
    <FilterList label="Gender">
      <FilterListItem label="Female" value={{ gender: "Female" }} />
      <FilterListItem label="Male" value={{ gender: "Male" }} />
      <FilterListItem label="Other" value={{ gender: "Other" }} />
      <FilterListItem label="Decline" value={{ gender: "Decline" }} />
    </FilterList>
    <FilterList label="Min Age">
      <FilterListItem
        label="25"
        value={{
          min_age: (new Date().getFullYear() - 25).toString() + "-00-00",
        }}
      />
      <FilterListItem
        label="30"
        value={{
          min_age: (new Date().getFullYear() - 30).toString() + "-00-00",
        }}
      />
      <FilterListItem
        label="35"
        value={{
          min_age: (new Date().getFullYear() - 35).toString() + "-00-00",
        }}
      />
      <FilterListItem
        label="40"
        value={{
          min_age: (new Date().getFullYear() - 40).toString() + "-00-00",
        }}
      />
    </FilterList>
    <FilterList label="Max Age">
      <FilterListItem
        label="30"
        value={{
          max_age: (new Date().getFullYear() - 30).toString() + "-00-00",
        }}
      />
      <FilterListItem
        label="35"
        value={{
          max_age: (new Date().getFullYear() - 35).toString() + "-00-00",
        }}
      />
      <FilterListItem
        label="40"
        value={{
          max_age: (new Date().getFullYear() - 40).toString() + "-00-00",
        }}
      />
      <FilterListItem
        label="45"
        value={{
          max_age: (new Date().getFullYear() - 45).toString() + "-00-00",
        }}
      />
    </FilterList>
    <FilterList label="Min TikTok Followers">
      <FilterListItem label="100" value={{ min_follower_count: 100 }} />
      <FilterListItem label="500" value={{ min_follower_count: 500 }} />
      <FilterListItem label="1000" value={{ min_follower_count: 1000 }} />
    </FilterList>
  </div>
);

const UserGrid = (props) => (
  <Datagrid {...props}>
    <TextField source="first_name" label="First Name" />
    <TextField source="last_name" label="Last Name" />
    <TextField source="credit" label="Points" />
    <TextField source="info_date_edited" label="Last info edit" />
    <TextField source="last_sms_time" label="Last SMS Time" />
    <TextField source="gender" label="Gender" />
    <TextField source="cognito_email" label="Email" />
    <TextField source="date_created" label="Date Joined" />
    <TextField
      source="social_tiktok.tiktok_user_info.data.user.follower_count"
      label="Follower#"
    />
    <UrlField
      source="social_tiktok.tiktok_user_info.data.user.profile_deep_link"
      label="TikTok Link"
    />
    <TextField source="cognito_birthdate" label="Birthdate" />
    <TextField label="no_share" source="no_partner_sharing" />
    <TextField label="unsub" source="unsubscribe" />
    <TextField source="date_updated" label="Date Updated" />
    <TextField source="zip_code" label="Zip Code" />
    <ShowButton basePath="/user" />
  </Datagrid>
);

const UserPagination = () => <Pagination rowsPerPageOptions={[50, 100, 200]} />;

export const UserList = (props) => {
  return (
    <List {...props} actions={null} pagination={<UserPagination />}>
      <Card>
        <CardContent style={{ display: "flex", paddingTop: "50px" }}>
          <Aside />
          <UserGrid
            {...props}
            bulkActionButtons={
              <>
                <CustomBulkActions />
                <BulkActionSendSms />
              </>
            }
          />
        </CardContent>
      </Card>
    </List>
  );
};

export const UserEdit = (props) => {
  return (
    <Edit title="Edit User" {...props}>
      <SimpleForm>
        <TextInput source="first_name" label="First Name" />
        <TextInput source="last_name" label="Last Name" />
        <TextInput source="gender" label="Gender" />
        <TextInput source="country_code" label="Country Code" />
        <TextInput source="zip_code" label="Zip Code" />
        <TextInput source="profile_picture" label="Profile Picture" />
        <DateInput source="birthday" label="Birthday" />
      </SimpleForm>
    </Edit>
  );
};



export const UserShow = (props) => {
  return (
    <Show>
      <SimpleShowLayout>
        <TextField source="pk" label="ID" />
        <TextField source="first_name" label="First Name" />
        <TextField source="last_name" label="Last Name" />
        <TextField source="gender" label="Gender" />
        <TextField source="cognito_email" label="Last Name" />
        <TextField source="cognito_phone_number" label="Phone Number" />
        <TextField source="cognito_birthdate" label="Birth Year" />
        <TextField source="street" label="Street" />
        <TextField source="apt" label="Apt" />
        <TextField source="city" label="City" />
        <TextField source="us_state" label="State" />
        <TextField source="zip_code" label="Zip Code" />
        <TextField
          source="social_tiktok.tiktok_user_info.data.user.follower_count"
          label="Tiktok follower"
        />
        <TextField
          source="social_tiktok.tiktok_user_info.data.user.profile_deep_link"
          label="Tiktok link"
        />
        <TextField source="no_partner_sharing" label="No Share" />
        <TextField source="unsubscribe" label="Unsub" />
      </SimpleShowLayout>
    </Show>
  );
};
