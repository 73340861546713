import React, { useState} from "react";
import FileUploadButton from "./FileUploadButton";
import { useNotify, useRefresh } from "react-admin";
import Header from "../auth/getHeader";
import { API } from "aws-amplify";

const FileUpload = ({ source, activityComment }) => {
  console.log(source)
  const id = source.split("+")[0];
  const tiktok_post_id = source.split("+")[1];
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState(false);
  const [fileSelected, setFileSelected] = useState(null);
  const [fileName, setFileName] = useState(""); // New state for the file name

  const notify = useNotify();
  const refresh = useRefresh();

  const uploadtoS3 = async (signedUrl, file) => {
    try {
      await fetch(signedUrl, {
        method: "PUT",
        body: file,
        headers: { "Content-Type": "" },
      });
    } catch (err) {
      setLoader(false);
      setError(true);
    }
  };

  const getSignedUrl = async (fileName) => {
    const header = await Header.getHeader();

    const x = await API.post("secure-api", "api/upload", {
      headers: {
        ...header,
        "Access-Control-Allow-Headers": "*",
        "Access-Control-Allow-Origin": "*",
      },
      body: {
        filename: fileName,
        campaign_id: tiktok_post_id.split("_")[0],
        activity_id: tiktok_post_id.split("_")[1],
        user_id: id.split("+")[0],
      },
    });

    return [x["signed_url"], x["filepath"]];
  };

  const handleFileChange = (e) => {
    const maxFileSize = 300 * 1024 * 1024;
    const selectedFile = e.target.files[0];

    if (selectedFile) {
      if (selectedFile.size > maxFileSize) {
        notify("The file must be 300MB or less.", "warning");
        return;
      }
      setFileSelected(selectedFile);
      setFileName(selectedFile.name); // Set the file name when a file is selected
    }

    e.target.value = null;
  };

  const handleUpload = async () => {
    if (!fileSelected) {
      notify("Please select a file.", "warning");
      return;
    }

    setLoader(true);
    setError(false);

    try {
      const [signedUrl, filepath] = await getSignedUrl(fileSelected.name);
      await uploadtoS3(signedUrl, fileSelected);

      const header = await Header.getHeader();
      await API.put(
        "secure-api",
        "api/admin/useractivity/" + id.split("+")[0] + "+" + tiktok_post_id,
        {
          headers: {
            ...header,
            "Access-Control-Allow-Headers": "*",
            "Access-Control-Allow-Origin": "*",
          },
          body: {
            review_video_file_path: filepath,
            user_activity_status: "assigned",
            activity_comment: activityComment,
          },
        }
      );

      notify("File uploaded successfully!", "info");

      setFileSelected(null);

      setLoader(false);
      refresh();
    } catch (err) {
      setError(true);
      setLoader(false);
      notify("Error uploading file.", "warning");
    }
  };

  return (
    <div>
      <FileUploadButton onFileSelect={handleFileChange} />
      {/* Render the file name if available */}
      {fileName && <p>Selected File: {fileName}</p>}
      {loader && <p>Uploading...</p>}
      {error && <p>Error uploading file. Please try again.</p>}
      <button onClick={handleUpload} disabled={!fileSelected || loader}>
        Upload File
      </button>
    </div>
  );
};

export default FileUpload;
